<template>
  <path id="Tracé_559" data-name="Tracé 559" d="M45.5,64a.765.765,0,0,1-.789-.637l-.576-4a12.436,12.436,0,0,1-2.579-1.426L37.8,59.45c-.365,0-.759,0-.941-.394l-3.034-5.248A.789.789,0,0,1,34,52.837l3.216-2.487a12.7,12.7,0,0,1,0-3.034L34,44.829a.75.75,0,0,1-.182-.97l3.034-5.248c.212-.394.607-.394.941-.394l3.761,1.516a15.114,15.114,0,0,1,2.579-1.426l.576-4a.787.787,0,0,1,.789-.637h6.067a.717.717,0,0,1,.7.637l.576,4a13.273,13.273,0,0,1,2.578,1.426l3.731-1.516c.394,0,.788,0,.97.394l3.034,5.248a.787.787,0,0,1-.182.97l-3.216,2.488a8.532,8.532,0,0,1,.121,1.516,12.862,12.862,0,0,1-.121,1.517L63,52.837a.788.788,0,0,1,.183.971l-3.034,5.248c-.212.394-.607.394-1,.394l-3.731-1.516a16.075,16.075,0,0,1-2.548,1.426l-.576,4a.773.773,0,0,1-.728.637ZM43.985,48.833a4.55,4.55,0,1,0,4.55-4.549A4.525,4.525,0,0,0,43.985,48.833Zm-14.542,9.21H26.369V34.31h7.858a19.086,19.086,0,0,0-4.784,23.731v0Zm-5.711,0H0V34.31H23.732V58.042h0Zm0-26.37H0V7.941H23.732V31.672h0Zm5.244-25.53L51.9,0l6.142,22.924L35.118,29.066Z"/>
</template>

<script>
export default {
name: "IconModuleParametrage"
}
</script>

<style scoped>

</style>